import { i18n } from '@boot/i18n';
import { computed } from '@vue/composition-api';

export default function() {
  const translate = computed(() => {
    return {
      translateRequired: ({ name }: any) => {
        return i18n.t('common.messages.required', { name });
      },
      translateIncompleteNumber: () => {
        return i18n.t('common.messages.incomplete_phone_number');
      },
      translateCanBeNoSpace: ({ name }: any) => {
        return i18n.t('common.messages.can_be_no_space', { name });
      },
      translateForgotPasswordSuccess: ({ email }: any) => {
        return i18n.t('forgot_password.success', { email });
      },
      translateNoData: ({ name }: any) => {
        return i18n.t('common.messages.no_data', { name });
      },
      translateInvalid: ({ name }: any) => {
        return i18n.t('common.messages.invalid', { name });
      },
      viewMode: ({ name }: any) => {
        return i18n.t('common.messages.view_mode', { name });
      },
      admins_page: {
        success_save_admin: i18n.t('common.admins_page.success_save_admin'),
        delete_success_admins: i18n.t(
          'common.admins_page.delete_success_admins'
        )
      },
      time_group_page: {
        time_group_success_save: i18n.t(
          'common.time_group_page.time_group_success_save'
        ),
        success_delete_group: i18n.t(
          'common.time_group_page.success_delete_group'
        ),
        success_delete_batch_group: i18n.t(
          'common.time_group_page.success_delete_batch_group'
        )
      },
      country: i18n.t('common.country'),
      strikethrough: i18n.t('common.strikethrough'),
      previously: i18n.t('common.previously'),
      dashboard: i18n.t('common.dashboard'),
      general_error: i18n.t('common.general_error'),
      list: i18n.t('common.list'),
      card: i18n.t('common.card'),
      report: i18n.tc('common.report', 1),
      reports: i18n.tc('common.report', 2),
      premium: i18n.t('common.premium'),
      reauthentication: i18n.t('common.reauthentication'),
      check_account: i18n.t('common.check_account'),
      option_title: i18n.t('common.option_title'),
      product_id: i18n.t('common.product_id'),
      characters: i18n.t('common.characters'),
      remove_session: i18n.t('common.remove_session'),
      category: i18n.t('common.category'),
      visibility: i18n.t('common.visibility'),
      preview: i18n.t('common.preview'),
      add_variable: i18n.t('common.add_variable'),
      bold: i18n.t('common.bold'),
      italic: i18n.t('common.italic'),
      strike: i18n.t('common.strike'),
      underline: i18n.t('common.underline'),
      paste: i18n.t('common.paste'),
      models: i18n.tc('common.models', 2),
      model: i18n.tc('common.models', 1),
      message_body: i18n.t('common.message_body'),
      clone: i18n.t('common.clone'),
      resend: i18n.t('common.resend'),
      initial: i18n.t('common.initial'),
      final: i18n.t('common.final'),
      example_template: i18n.t('common.example_template'),
      type_action: i18n.t('common.type_action'),
      close: i18n.t('common.close'),
      select: i18n.t('common.select'),
      business_hours: i18n.t('common.business_hours'),
      interval: i18n.t('common.interval'),
      update_interval: i18n.t('common.update_interval'),
      media_type: i18n.t('common.media_type'),
      header_type: i18n.t('common.header_type'),
      contents: i18n.t('common.contents'),
      message_templates: i18n.t('common.message_templates'),
      filter: i18n.t('common.filter'),
      approved: i18n.t('common.approved'),
      in_analysis: i18n.t('common.in_analysis'),
      pending: i18n.t('common.pending'),
      unsynchronized: i18n.t('common.unsynchronized'),
      refused: i18n.t('common.refused'),
      apply: i18n.t('common.apply'),
      clean: i18n.t('common.clean'),
      artificial_intelligence: i18n.t('common.artificial_intelligence'),
      contexts: i18n.t('common.contexts'),
      security_privacy: i18n.t('common.security_privacy'),
      flow: i18n.t('common.flow'),
      channel: i18n.tc('common.channel', 1),
      channels: i18n.tc('common.channel', 2),
      batch: i18n.t('common.batch'),
      required: i18n.t('common.required'),
      login: i18n.t('common.login'),
      ad_account: i18n.t('common.ad_account'),
      integrate: i18n.t('common.integrate'),
      recover_password: i18n.t('common.recover_password'),
      function: i18n.t('common.function'),
      advance: i18n.t('common.advance'),
      conclude: i18n.t('common.conclude'),
      message_extract: i18n.t('common.message_extract'),
      contact: i18n.tc('common.contact', 1),
      contacts: i18n.tc('common.contact', 2),
      config: i18n.tc('common.config', 1),
      configs: i18n.tc('common.config', 2),
      team: i18n.tc('common.team', 1),
      teams: i18n.tc('common.team', 2),
      pause: i18n.tc('common.pause', 1),
      pauses: i18n.tc('common.pause', 2),
      administrator: i18n.tc('common.administrator', 1),
      administrators: i18n.tc('common.administrator', 2),
      settings: i18n.t('common.settings'),
      add: i18n.t('common.add'),
      documentation: i18n.tc('common.documentation'),
      changelog: i18n.tc('common.changelog'),
      status: i18n.tc('common.status'),
      support: i18n.tc('common.support'),
      integration: i18n.tc('common.integration', 1),
      integrations: i18n.tc('common.integration', 2),
      aplication: i18n.t('common.aplication'),
      introduction: i18n.t('common.introduction'),
      additional_fields: i18n.t('common.additional_fields'),
      input_fields: i18n.t('common.input_fields'),
      time: i18n.t('common.time'),
      customization: i18n.t('common.customization'),
      multichannel: i18n.t('common.multichannel'),
      api: i18n.t('common.api'),
      rest: i18n.t('common.rest'),
      third: i18n.t('common.third'),
      hsm: i18n.t('common.hsm'),
      hsm_template: i18n.t('common.hsm_template'),
      seconds: i18n.t('common.seconds'),
      minutes: i18n.t('common.minutes'),
      not_applicable: i18n.t('common.not_applicable'),
      copy_token_auth: i18n.t('common.copy_token_auth'),
      generate_token_auth: i18n.t('common.generate_token_auth'),
      generate_api_key: i18n.t('common.generate_api_key'),
      disabled: i18n.t('common.disabled'),
      rd_station: i18n.t('common.rd_station'),
      telephone_exchange: i18n.t('common.telephone_exchange'),
      client_secret: i18n.t('common.client_secret'),
      app_id: i18n.t('common.app_id'),
      import_contacts: i18n.t('common.import_contacts'),
      time_group: i18n.t('common.time_group'),
      user_group: i18n.t('common.user_group'),
      word_filter: i18n.t('common.word_filter'),
      remote_support: i18n.t('common.remote_support'),
      custom_variables: i18n.t('common.custom_variables'),
      tabulation: i18n.tc('common.tabulation', 1),
      tabulations: i18n.tc('common.tabulation', 2),
      tag: i18n.tc('common.tags', 1),
      tags: i18n.tc('common.tags', 2),
      parameter: i18n.tc('common.parameter', 1),
      parameters: i18n.tc('common.parameter', 2),
      about: i18n.t('common.about'),
      token: i18n.t('common.token'),
      name: i18n.t('common.name'),
      title: i18n.t('common.title'),
      caption: i18n.t('common.caption'),
      appearance: i18n.t('common.appearance'),
      text: i18n.t('common.text'),
      message: i18n.t('common.message'),
      enter_your_message: i18n.t('common.enter_your_message'),
      copy_identifier: i18n.t('common.copy_identifier'),
      copy: i18n.t('common.copy'),
      copy_webhook: i18n.t('common.copy_webhook'),
      authenticate: i18n.t('common.authenticate'),
      activation_code: i18n.t('common.activation_code'),
      restart: i18n.t('common.restart'),
      activate: i18n.t('common.activate'),
      url: i18n.t('common.url'),
      header: i18n.t('common.header'),
      body: i18n.t('common.body'),
      value: i18n.t('common.value'),
      botname: i18n.t('common.botname'),
      botlink: i18n.t('common.botlink'),
      without_permission: i18n.t('common.without_permission'),
      add_option: i18n.t('common.add_option'),
      add_product: i18n.t('common.add_product'),
      account_name: i18n.t('common.account_name'),
      ra_page_id: i18n.t('common.ra_page_id'),
      ra_success: i18n.t('common.ra_success'),
      account_password: i18n.t('common.account_password'),
      help: i18n.t('common.help'),
      indicator: i18n.tc('common.indicator', 1),
      indicators: i18n.tc('common.indicator', 2),
      page: i18n.tc('common.page', 1),
      pages: i18n.tc('common.page', 2),
      tip: i18n.tc('common.tip', 1),
      tips: i18n.tc('common.tip', 2),
      type: i18n.tc('common.type', 1),
      types: i18n.tc('common.type', 2),
      number: i18n.tc('common.number', 1),
      numbers: i18n.tc('common.number', 2),
      spacing: i18n.tc('common.spacing', 1),
      spaces: i18n.tc('common.spacing', 2),
      file: i18n.tc('common.file', 1),
      files: i18n.tc('common.file', 2),
      storage: i18n.t('common.storage'),
      monitoring: i18n.t('common.monitoring'),
      notification: i18n.t('common.notification'),
      description: i18n.t('common.description'),
      quota: i18n.t('common.quota'),
      start_hour: i18n.t('common.start_hour'),
      end_hour: i18n.t('common.end_hour'),
      method: i18n.t('common.method'),
      auth_method: i18n.t('common.auth_method'),
      none: i18n.t('common.none'),
      all: i18n.t('common.all'),
      agents_only: i18n.t('common.agents_only'),
      admins_only: i18n.t('common.admins_only'),
      email: i18n.t('common.email'),
      turn_on: i18n.t('common.turn_on'),
      list_title: i18n.t('common.list_title'),
      see_items: i18n.t('common.see_items'),
      multiproduct: i18n.t('common.multiproduct'),
      optional: i18n.t('common.optional'),
      add_session: i18n.t('common.add_session'),
      recovery_email: i18n.t('common.recovery_email'),
      password: i18n.t('common.password'),
      redefine_password: i18n.t('common.redefine_password'),
      current_password: i18n.t('common.current_password'),
      new_password: i18n.t('common.new_password'),
      confirm_password: i18n.t('common.confirm_password'),
      audit: i18n.t('common.audit'),
      remember: i18n.t('common.remember'),
      agent: i18n.tc('common.agent', 1),
      agents: i18n.tc('common.agent', 2),
      attendance: i18n.tc('common.attendance', 1),
      attendances: i18n.tc('common.attendance', 2),
      conversation: i18n.tc('common.conversation', 1),
      conversations: i18n.tc('common.conversation', 2),
      export: i18n.t('common.export'),
      version: i18n.t('common.version'),
      default: i18n.t('common.default'),
      variable: i18n.tc('common.variable', 1),
      variables: i18n.tc('common.variable', 2),
      day: i18n.tc('common.day', 1),
      days: i18n.tc('common.day', 2),
      hour: i18n.tc('common.hour', 1),
      hours: i18n.tc('common.hour', 2),
      week: i18n.tc('common.week', 1),
      weeks: i18n.tc('common.week', 2),
      comment: i18n.tc('common.comment', 1),
      comments: i18n.tc('common.comment', 2),
      user: i18n.tc('common.user', 1),
      users: i18n.tc('common.user', 2),
      other: i18n.tc('common.other', 1),
      others: i18n.tc('common.other', 2),
      post: i18n.t('common.post'),
      port: i18n.t('common.port'),
      protocol: i18n.t('common.protocol'),
      ending_message: i18n.t('common.ending_message'),
      message_box: i18n.t('common.message_box'),
      slide: i18n.t('common.slide'),
      increase: i18n.t('common.increase'),
      gradually_appear: i18n.t('common.gradually_appear'),
      increase_rotated: i18n.t('common.increase_rotated'),
      main_color: i18n.t('common.main_color'),
      button_color: i18n.t('common.button_color'),
      alert_color: i18n.t('common.alert_color'),
      pop_up_color: i18n.t('common.pop_up_color'),
      button: i18n.t('common.button'),
      buttons: i18n.t('common.buttons'),
      button_text: i18n.t('common.button_text'),
      image: i18n.t('common.image'),
      position: i18n.t('common.position'),
      animation: i18n.t('common.animation'),
      label: i18n.t('common.label'),
      color: i18n.t('common.color'),
      font_color: i18n.t('common.font_color'),
      light: i18n.t('common.light'),
      dark: i18n.t('common.dark'),
      host: i18n.t('common.host'),
      provider: i18n.t('common.provider'),
      hash: i18n.t('common.hash'),
      control: i18n.t('common.control'),
      log_in: i18n.t('common.log_in'),
      contact_center: i18n.t('common.contact_center'),
      rule: i18n.tc('common.rules', 1),
      rules: i18n.tc('common.rules', 2),
      attention: i18n.t('common.attention'),
      initial_message: i18n.t('common.initial_message'),
      automatic_answer: i18n.t('common.automatic_answer'),
      default_team: i18n.t('common.default_team'),
      attendance_automatic: i18n.t('common.attendance_automatic'),
      forgot_password: i18n.t('common.forgot_password'),
      login_title: i18n.t('common.login_title'),
      period: i18n.t('common.period'),
      footer: i18n.t('common.footer'),
      icon: i18n.t('common.icon'),
      form_title: i18n.t('common.form_title'),
      upload_icon: i18n.t('common.upload_icon'),
      icon_color: i18n.t('common.icon_color'),
      language: i18n.t('common.language'),
      announcement: i18n.t('common.announcement'),
      search: i18n.t('common.search'),
      rows_per_page: i18n.t('common.rows_per_page'),
      able: i18n.t('common.able'),
      connected: i18n.t('common.connected'),
      disconnected: i18n.t('common.disconnected'),
      qrcode: i18n.t('common.qrcode'),
      left: i18n.t('common.left'),
      right: i18n.t('common.right'),
      of: i18n.t('common.of'),
      aware: i18n.t('common.aware'),
      unlock_for_trust: i18n.t('common.unlock_for_trust'),
      access_now: i18n.t('common.access_now'),
      login_email: i18n.t('common.login_email'),
      smtp_error: i18n.t('common.smtp_error'),
      error_imap_and_smtp: i18n.t('common.error_imap_and_smtp'),
      error_imap: i18n.t('common.error_imap'),
      starting_process: i18n.t('common.starting_process'),
      pending_auth: i18n.t('common.pending_auth'),
      enable_qrcode: i18n.t('common.enable_qrcode'),
      send_schedule: i18n.t('common.send_schedule'),
      send_media_with_link: i18n.t('common.send_media_with_link'),
      receptive: i18n.t('common.receptive'),
      teams_gtw: i18n.t('common.teams_gtw'),
      enable_batch_delivery: i18n.t('common.enable_batch_delivery'),
      previous_page: i18n.t('common.previous_page'),
      yes: i18n.t('common.yes'),
      no: i18n.t('common.no'),
      webhook_url: i18n.t('common.webhook_url'),
      oauth_redirect_url: i18n.t('common.oauth_redirect_url'),
      login_or: i18n.t('common.login_or'),
      authenticationMethod: i18n.t('common.authenticationMethod'),
      mode: i18n.t('common.mode'),
      test: i18n.t('common.test'),
      result: i18n.t('common.result'),
      custom: i18n.t('common.custom'),
      script_test: i18n.t('common.script_test'),
      lang: {
        pt: i18n.t('common.lang.pt-br'),
        en: i18n.t('common.lang.en'),
        es: i18n.t('common.lang.es')
      },
      action: i18n.tc('common.actions.self', 1),
      actions: i18n.tc('common.actions.self', 2),
      edit: i18n.t('common.actions.edit'),
      remove: i18n.t('common.actions.remove'),
      save: i18n.t('common.actions.save'),
      back: i18n.t('common.actions.back'),
      cancel: i18n.t('common.actions.cancel'),
      list_colors: {
        yellow: i18n.t('common.list_colors.yellow'),
        blue: i18n.t('common.list_colors.blue'),
        green: i18n.t('common.list_colors.green'),
        grey: i18n.t('common.list_colors.grey'),
        purple: i18n.t('common.list_colors.purple'),
        brown: i18n.t('common.list_colors.brown'),
        red: i18n.t('common.list_colors.red'),
        violet: i18n.t('common.list_colors.violet'),
        pink: i18n.t('common.list_colors.pink'),
        black: i18n.t('common.list_colors.black')
      },
      components: {
        facebook_reaction: i18n.t('common.components.facebook_reaction'),
        attachment: i18n.t('common.components.attachment'),
        receive_attachment: i18n.t('common.components.receive_attachment'),
        menu: i18n.t('common.components.menu'),
        webchat_event: i18n.t('common.components.webchat_event'),
        template_message: i18n.t('common.components.template_message'),
        location: i18n.t('common.components.location'),
        service_note: i18n.t('common.components.service_note'),
        condition: i18n.t('common.components.condition'),
        input: i18n.t('common.components.input')
      },
      messages: {
        required_office365: i18n.t('common.messages.required_office365'),
        image_size: i18n.t('common.messages.image_size'),
        auth_verify: i18n.t('common.messages.auth_verify'),
        invalid_character: i18n.t('common.messages.invalid_character'),
        flow_invalid: i18n.t('common.messages.flow_invalid'),
        licence_limit_per_channel_reached: i18n.t(
          'common.messages.licence_limit_per_channel_reached'
        ),
        channel_already_exists_in_another_licence: i18n.t(
          'common.messages.channel_already_exists_in_another_licence'
        ),
        email_settings_not_found: i18n.t(
          'common.messages.email_settings_not_found'
        ),
        reauthentication_required: i18n.t(
          'common.messages.reauthentication_required'
        ),
        automatic_spacing: i18n.t('common.messages.automatic_spacing'),
        no_result: i18n.t('common.messages.no_result'),
        channel_period: i18n.t('common.messages.channel_period'),
        are_you_sure: i18n.t('common.messages.are_you_sure'),
        url_webhook: i18n.t('common.messages.url_webhook'),
        restart_channel: i18n.t('common.messages.restart_channel'),
        you_really_delete: i18n.t('common.messages.you_really_delete'),
        really_want_to_resend: i18n.t('common.messages.really_want_to_resend'),
        redirect_to_update: i18n.t('common.messages.redirect_to_update'),
        selected_platform: i18n.t('common.messages.selected_platform'),
        success: i18n.t('common.messages.success'),
        button_customization: i18n.t('common.messages.button_customization'),
        chat_customization: i18n.t('common.messages.chat_customization'),
        copy: i18n.t('common.messages.copy'),
        can_i_help_you: i18n.t('common.messages.can_i_help_you'),
        only_cell: i18n.t('common.messages.only_cell'),
        message_color: i18n.t('common.messages.message_color'),
        you_really_end_service: i18n.t(
          'common.messages.you_really_end_service'
        ),
        session_end: i18n.t('common.messages.session_end'),
        select_image_button: i18n.t('common.messages.select_image_button'),
        character_limit: i18n.t('common.messages.character_limit'),
        welcome_message: i18n.t('common.messages.welcome_message'),
        select_default_image: i18n.t('common.messages.select_default_image'),
        edit_user_information: i18n.t('common.messages.edit_user_information'),
        error: i18n.t('common.messages.error'),
        error_email: i18n.t('common.messages.error_email'),
        password_not_match: i18n.t('common.messages.password_not_match'),
        unlock_for_trust: i18n.t('common.messages.unlock_for_trust'),
        alert_newsletter: i18n.t('common.messages.alert_newsletter')
      },
      signin_message: {
        login_back: i18n.t('signin_message.login_back'),
        auth_remote_message: i18n.t('signin_message.auth_remote_message'),
        username_password_match: i18n.t(
          'signin_message.username_password_match'
        ),
        expired_password: i18n.t('signin_message.expired_password'),
        lock_prediction: ({ days }) => {
          return i18n.t('signin_message.lock_prediction', { days });
        },
        locked_system: i18n.t('signin_message.locked_system')
      },
      marketplace_screen: {
        messages: {
          expand_power: i18n.t('marketplace_screen.messages.expand_power'),
          marketplace_collection: i18n.t(
            'marketplace_screen.messages.marketplace_collection'
          ),
          choose_below: i18n.t('marketplace_screen.messages.choose_below')
        }
      },
      hsm_screen: {
        messages: {
          image_size_limit: i18n.t('hsm_screen.messages.image_size_limit'),
          body_example: i18n.t('hsm_screen.messages.body_example'),
          enable_device: i18n.t('hsm_screen.messages.enable_device'),
          disable_device: i18n.t('hsm_screen.messages.disable_device'),
          updated_variables: i18n.t('hsm_screen.messages.updated_variables'),
          message_required: i18n.t('hsm_screen.messages.message_required'),
          no_result_channel: i18n.t('hsm_screen.messages.no_result_channel'),
          no_result_dialog: i18n.t('hsm_screen.messages.no_result_dialog'),
          template_example_message: i18n.t(
            'hsm_screen.messages.template_example_message'
          ),
          alert_example_message: i18n.t(
            'hsm_screen.messages.alert_example_message'
          )
        },
        no_registration_variable: i18n.t('hsm_screen.no_registration_variable'),
        type_repetition: i18n.t('hsm_screen.type_repetition'),
        status_error: i18n.t('hsm_screen.status_error'),
        status_reject: i18n.t('hsm_screen.status_reject'),
        in_analysis: i18n.t('hsm_screen.in_analysis'),
        type_to_replace: i18n.t('hsm_screen.type_to_replace'),
        template_exists: i18n.t('hsm_screen.template_exists'),
        select_template: i18n.t('hsm_screen.select_template'),
        select_models: i18n.t('hsm_screen.select_models'),
        choose_model: i18n.t('hsm_screen.choose_model'),
        button_type_quick_reply: i18n.t('hsm_screen.button_type_quick_reply'),
        button_type_action: i18n.t('hsm_screen.button_type_action'),
        phone: i18n.t('hsm_screen.phone'),
        head_type_none: i18n.t('hsm_screen.head_type_none'),
        head_type_text: i18n.t('hsm_screen.head_type_text'),
        head_type_image: i18n.t('hsm_screen.head_type_image'),
        head_type_document: i18n.t('hsm_screen.head_type_document'),
        head_type_video: i18n.t('hsm_screen.head_type_video'),
        message_button_limit: i18n.t('hsm_screen.message_button_limit'),
        message_option_limit: i18n.t('hsm_screen.message_option_limit'),
        message_section_limit: i18n.t('hsm_screen.message_section_limit'),
        message_example_not_found: i18n.t(
          'hsm_screen.message_example_not_found'
        ),
        message_device_not_found: i18n.t('hsm_screen.message_device_not_found')
      },
      hsm_categories: {
        account_update: i18n.t('hsm_categories.account_update'),
        payment_update: i18n.t('hsm_categories.payment_update'),
        personal_finance_update: i18n.t(
          'hsm_categories.personal_finance_update'
        ),
        shipping_update: i18n.t('hsm_categories.shipping_update'),
        reservation_update: i18n.t('hsm_categories.reservation_update'),
        issue_resolution: i18n.t('hsm_categories.issue_resolution'),
        appointment_update: i18n.t('hsm_categories.appointment_update'),
        transportation_update: i18n.t('hsm_categories.transportation_update'),
        ticket_update: i18n.t('hsm_categories.ticket_update'),
        alert_update: i18n.t('hsm_categories.alert_update'),
        auto_reply: i18n.t('hsm_categories.auto_reply')
      },
      hsm_visibility: {
        all: i18n.t('hsm_visibility.all'),
        agents_only: i18n.t('hsm_visibility.agents_only'),
        admins_only: i18n.t('hsm_visibility.admins_only'),
        none: i18n.t('hsm_visibility.none')
      },
      channel_screen: {
        my_business: {
          configuration_auth: i18n.t(
            'channel_screen.my_business.configuration_auth'
          )
        },
        messages: {
          generic_type: i18n.t('channel_screen.messages.generic_type'),
          webhook: {
            inbound: i18n.t('channel_screen.messages.webhook.inbound'),
            outbound: i18n.t('channel_screen.messages.webhook.outbound')
          },
          include_channel: i18n.t('channel_screen.messages.include_channel'),
          token_generate: i18n.t('channel_screen.messages.token_generate'),
          apikey_generate: i18n.t('channel_screen.messages.apikey_generate'),
          apikey_info: i18n.t('channel_screen.messages.apikey_info'),
          webhook_register: i18n.t('channel_screen.messages.webhook_register'),
          check_account: i18n.t('channel_screen.messages.check_account'),
          configuration_bsp: i18n.t(
            'channel_screen.messages.configuration_bsp'
          ),
          edit_channel: i18n.t('channel_screen.messages.edit_channel'),
          session_open_channel_remove: i18n.t(
            'channel_screen.messages.session_open_channel_remove'
          ),
          generic_channel_edit: i18n.t(
            'channel_screen.messages.generic_channel_edit'
          ),
          generic_channel_add: i18n.t(
            'channel_screen.messages.generic_channel_add'
          ),
          enable_receptive: i18n.t('channel_screen.messages.enable_receptive'),
          wait_one_minutes: i18n.t('channel_screen.messages.wait_one_minutes'),
          disable_receptive: i18n.t(
            'channel_screen.messages.disable_receptive'
          ),
          enable_channel: i18n.t('channel_screen.messages.enable_channel'),
          disable_channel: i18n.t('channel_screen.messages.disable_channel')
        },
        webchat: {
          ask_information: i18n.t('channel_screen.webchat.ask_information'),
          display_button_message: i18n.t(
            'channel_screen.webchat.display_button_message'
          ),
          display_button_chat: i18n.t(
            'channel_screen.webchat.display_button_chat'
          ),
          image_dimension: i18n.t('channel_screen.webchat.image_dimension'),
          choose_animation: i18n.t('channel_screen.webchat.choose_animation'),
          keep_keyboard: i18n.t('channel_screen.webchat.keep_keyboard'),
          close_chat_after: i18n.t('channel_screen.webchat.close_chat_after'),
          allow_ballon: i18n.t('channel_screen.webchat.allow_ballon'),
          display_welcome_message: i18n.t(
            'channel_screen.webchat.display_welcome_message'
          ),
          show_message_chat: i18n.t('channel_screen.webchat.show_message_chat'),
          display_online: i18n.t('channel_screen.webchat.display_online'),
          select_service: i18n.t('channel_screen.webchat.select_service'),
          enable_active_webchat: i18n.t(
            'channel_screen.webchat.enable_active_webchat'
          ),
          information_starting: i18n.t(
            'channel_screen.webchat.information_starting'
          ),
          webchat_users_notification: i18n.t(
            'channel_screen.webchat.webchat_users_notification'
          ),
          select_image_webchat: i18n.t(
            'channel_screen.webchat.select_image_webchat'
          )
        },
        teams: {
          microsoft_app_id: i18n.t('channel_screen.teams.microsoft_app_id'),
          microsoft_app_password: i18n.t(
            'channel_screen.teams.microsoft_app_password'
          )
        }
      },
      webchat: {
        tip: i18n.t('webchat.tip')
      },
      active_contact: {
        active_contact_title: i18n.t('active_contact.active_contact_title'),
        active_contact_confirmation: i18n.t(
          'active_contact.active_contact_confirmation'
        ),
        models_not_found: i18n.t('active_contact.models_not_found'),
        labels: {
          campaigns: {
            label: i18n.t('active_contact.labels.campaigns.label'),
            placeholder: i18n.t('active_contact.labels.campaigns.placeholder')
          },
          channels: {
            label: i18n.t('active_contact.labels.channels.label'),
            placeholder: i18n.t('active_contact.labels.channels.placeholder')
          },
          active_sessions: i18n.t('active_contact.labels.active_sessions'),
          custom_variable: i18n.t('active_contact.labels.custom_variable'),
          variables: i18n.t('active_contact.labels.variables'),
          filter: i18n.t('active_contact.labels.filter'),
          name: i18n.t('active_contact.labels.name'),
          message: i18n.t('active_contact.labels.message'),
          category: i18n.t('active_contact.labels.category')
        },
        customer_care: {
          title: i18n.t('active_contact.customer_care.title'),
          description: i18n.t('active_contact.customer_care.description')
        },
        click_to_whatsapp: {
          title: i18n.t('active_contact.click_to_whatsapp.title'),
          description: i18n.t('active_contact.click_to_whatsapp.description')
        },
        service: {
          title: i18n.t('active_contact.service.title'),
          description: i18n.t('active_contact.service.description'),
          tip: i18n.t('active_contact.service.tip')
        },
        marketing: {
          title: i18n.t('active_contact.marketing.title'),
          description: i18n.t('active_contact.marketing.description'),
          tip: i18n.t('active_contact.marketing.tip')
        },
        utility: {
          title: i18n.t('active_contact.utility.title'),
          description: i18n.t('active_contact.utility.description'),
          tip: i18n.t('active_contact.utility.tip')
        },
        authentication: {
          title: i18n.t('active_contact.authentication.title'),
          description: i18n.t('active_contact.authentication.description'),
          tip: i18n.t('active_contact.authentication.tip')
        },
        buttons: {
          cancel: i18n.t('active_contact.buttons.cancel'),
          next: i18n.t('active_contact.buttons.next'),
          back: i18n.t('active_contact.buttons.back'),
          send: i18n.t('active_contact.buttons.send'),
          apply: i18n.t('active_contact.buttons.apply'),
          clear: i18n.t('active_contact.buttons.clear'),
          confirm: i18n.t('active_contact.buttons.confirm'),
          send_free_text: i18n.t('active_contact.buttons.send_free_text')
        },
        dialog: {
          title: i18n.t('active_contact.dialog.title'),
          description: i18n.t('active_contact.dialog.description')
        }
      },
      authentication_template: {
        example_message: i18n.t('authentication_template.example_message'),
        advisory_message: i18n.t('authentication_template.advisory_message'),
        button_message: i18n.t('authentication_template.button_message'),
        footer_message: i18n.t('authentication_template.footer_message'),
        example_footer: i18n.t('authentication_template.example_footer'),
        code_delivery: {
          title: i18n.t('authentication_template.code_delivery.title'),
          description: i18n.t(
            'authentication_template.code_delivery.description'
          )
        },
        copy_code: {
          title: i18n.t('authentication_template.copy_code.title'),
          description: i18n.t('authentication_template.copy_code.description'),
          button_info: i18n.t('authentication_template.copy_code.button_info'),
          button_tip: i18n.t('authentication_template.copy_code.button_tip')
        },
        text_button: {
          title: i18n.t('authentication_template.text_button.title'),
          description: i18n.t('authentication_template.text_button.description')
        },
        message_content: {
          title: i18n.t('authentication_template.message_content.title'),
          description: i18n.t(
            'authentication_template.message_content.description'
          )
        },
        labels: {
          add_advisory: i18n.t('authentication_template.labels.add_advisory'),
          add_expires: i18n.t('authentication_template.labels.add_expires'),
          expires_in: i18n.t('authentication_template.labels.expires_in'),
          expires: i18n.t('authentication_template.labels.expires'),
          variable: i18n.t('authentication_template.labels.variable'),
          placeholder: i18n.t('authentication_template.labels.placeholder')
        }
      },
      errors: {
        only_json_file: i18n.t('errors.only_json_file'),
        access_denied: i18n.t('errors.access_denied'),
        access_denied_subtitle: i18n.t('errors.access_denied_subtitle'),
        not_found: i18n.t('errors.not_found'),
        not_found_subtitle: i18n.t('errors.not_found_subtitle'),
        failed_authenticate_remote: i18n.t(
          'errors.failed_authenticate_unaccredited'
        ),
        failed_server_remote: i18n.t('errors.failed_server_remote'),
        failed_authenticate_unaccredited: i18n.t(
          'errors.failed_authenticate_unaccredited'
        ),
        failed_authenticate_profile: i18n.t(
          'errors.failed_authenticate_profile'
        ),
        authenticate_remote_not_possible: i18n.t(
          'errors.authenticate_remote_not_possible'
        ),
        no_active_team: i18n.t('errors.no_active_team'),
        flow_not_exist: i18n.t('errors.flow_not_exist'),
        ra_auth_error: i18n.t('errors.ra_auth_error'),
        channel_not_provided: i18n.t('errors.channel_not_provided'),
        channel_generate_apikey_error: i18n.t(
          'errors.channel_generate_apikey_error'
        ),
        max_sessions: i18n.t('errors.max_sessions'),
        max_product_items: i18n.t('errors.max_product_items'),
        duplicate_product_id: i18n.t('errors.duplicate_product_id'),
        update_error_in_less_than_30_days: i18n.t(
          'errors.update_error_in_less_than_30_days'
        ),
        update_error_in_less_than_24_hours: i18n.t(
          'errors.update_error_in_less_than_24_hours'
        ),
        not_found_userhub: i18n.t('errors.not_found_userhub'),
        not_found_meta_id: i18n.t('errors.not_found_meta_id'),
        failed_authenticate_remote_client: ({ host }) => {
          return i18n.t('errors.failed_authenticate_remote_client', { host });
        }
      },
      invalid: {
        login_disabled: i18n.t('invalid.login_disabled'),
        max_agent_simult: i18n.t('invalid.max_agent_simult'),
        out_of_hours: i18n.t('invalid.out_of_hours'),
        empty_campaign: i18n.t('invalid.empty_campaign')
      }
    };
  });

  return { translate };
}
